export enum CalendarRanges {
  SELF_RANGE = 0,
  TODAY = 1,
  YESTERDAY = 2,
  WEEK_AGO = 3,
  MONTH_AGO = 4,
  HALF_YEAR = 5,
  QUARTER = 6,
  DAYS_30 = 7
}

export type TChipUnit = {
  key: CalendarRanges;
  label: string;
  dates: [Date | number, Date | number];
};
