import { defineComponent, PropType, ref, watch, onUnmounted, onMounted } from 'vue';
import { useField } from 'vee-validate';
import Calendar from 'primevue/calendar';
import type { TDateFilters } from '@/store/modules/models/state';
import { YEAR_RANGE } from '@/dictionaries/locale/locale.ru';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import { CalendarRanges, TChipUnit } from '@/types/calendarDefaultRanges';
import type { TCalendarField } from '@/types/fieldsType';

// const WEEK = 7;
// const MONTH = 30;
// const DAY = 1;

export default defineComponent({
  name: 'BaseInputCalendar',

  components: {
    Calendar,
    BaseButton
  },

  props: {
    label: {
      type: String
    },
    value: {
      type: Array as PropType<TDateFilters>
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String
    },
    hasRemoveIcon: {
      type: Boolean,
      default: false
    },
    chipsList: {
      type: Array as PropType<TChipUnit[]>,
      default: []
    }
  },

  setup(props, { emit }) {
    const {
      value: inputValue,
      errorMessage,
      handleInput
    } = useField<TCalendarField>(props.name, undefined);

    const activeButton = ref();

    watch(() => props.value, () => {
      if (props.value) {
        inputValue.value = props.value.map((item) => new Date(item));
      } else {
        inputValue.value = null;
      }
    });

    const selectDate = () => {
      emit('changed', inputValue.value);
    };

    const isValidDateFormat = (event: TDateFilters) => {
      const endDate = event ? event[1] : null;
      return Array.isArray(event) && (endDate !== null);
    };

    const setInputDate = (event: TDateFilters) => {
      activeButton.value = CalendarRanges.SELF_RANGE;
      if (isValidDateFormat(event)) {
        emit('changed', event);
      }
    };

    const setDates = (dates: [Date, Date], key: CalendarRanges) => {
      const start = dates[0];
      const end = dates[1];
      inputValue.value = [start, end];
      emit('changed', [start, end]);
      activeButton.value = key;
    };

    // const changedText = (event: HTMLInputEvent) => {
    //   const firstDate = new Date(event.target.value.split('-')[0]);
    //   const secondDate = new Date(event.target.value.split('-')[1]);
    //   // primevue bug, check one day range by hands
    //   if (secondDate.getTime() === firstDate.getTime()) {
    //     emit('changed', [firstDate, secondDate]);
    //   }
    //   inputValue.value = event.target.value;
    //   event.target.value === '' && emit('changed', null);
    // };
    // const setDaysAgo = (date: Date, daysAgo: number) => {
    //   return new Date(date.setDate(date.getDate() - daysAgo));
    // };
    // const setToday = () => {
    //   const today = (new Date());
    //   const todayEnd = (new Date());
    //   inputValue.value = [today, todayEnd];
    //   emit('changed', [today, todayEnd]);
    //   activeButton.value = CalendarRanges.TODAY;
    // };
    //
    // const setYesterday = () => {
    //   const yesterday = (setDaysAgo(new Date(), DAY));
    //   const yesterdayEnd = (setDaysAgo(new Date(), DAY));
    //   inputValue.value = [yesterday, yesterdayEnd];
    //   emit('changed', [yesterday, yesterdayEnd]);
    //   activeButton.value = CalendarRanges.YESTERDAY;
    // };
    // const setLastWeek = () => {
    //   const [weekAgo, today] = [
    //     (setDaysAgo(new Date(), WEEK)),
    //     (new Date())
    //   ];
    //   inputValue.value = [weekAgo, today];
    //   emit('changed', [weekAgo, today]);
    //   activeButton.value = CalendarRanges.WEEK_AGO;
    // };
    // const setLastMonth = () => {
    //   const [monthAgo, today] = [
    //     (setDaysAgo(new Date(), MONTH)),
    //     (new Date())
    //   ];
    //   inputValue.value = [monthAgo, today];
    //   emit('changed', [monthAgo, today]);
    //   activeButton.value = CalendarRanges.MONTH_AGO;
    // };

    const resetField = () => {
      inputValue.value = '';
      emit('changed', null);
    };

    const isActiveButton = (range: CalendarRanges) => {
      return range === activeButton.value;
    };

    onUnmounted(() => {
      inputValue.value = null;
    });

    onMounted(() => {
      if (props.value) {
        inputValue.value = props.value.map((item) => new Date(item));
      } else {
        inputValue.value = null;
      }
    });

    return {
      setDates,
      inputValue,
      errorMessage,
      selectDate,
      handleInput,
      setInputDate,
      isActiveButton,
      resetField,
      YEAR_RANGE,
      CalendarRanges
    };
  }
});
