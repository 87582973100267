import { InventionFilter, TFilters } from '@/store/modules/models/state';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { MODELS_MUTATIONS_TYPE, PAGINATOR_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import defaultPaginatorSettings from '@/dictionaries/defaultPaginatorSettings/defaultPaginatorSettings';
import store from '@/store';
import type { TQuery } from '@/types/queryPaginatorAndFiltersType';
import type { TPaginator } from '@/store/modules/paginator/state';

type TCheckNumber = string | undefined | null | number;

const isPositiveIntNumber = (number: TCheckNumber): boolean => {
  return !!(number &&
    Number.isInteger(+number) &&
    Math.sign(+number) === 1
  );
};

const isCorrectDate = (query: TQuery): boolean => {
  return !!(query.date &&
    Array.isArray(query.date) &&
    ((new Date(query.date[1])) >= (new Date(query.date[0]))));
};

const setPaginatorAndFiltersFromQuery = (query: TQuery) => {
  const setPaginator: TPaginator = {
    size: store.state.paginator?.paginator.size!,
    page: defaultPaginatorSettings.first
  };
  const setFilters: TFilters = {
    query: null,
    mkpoClassId: null,
    date: null,
    inventionFilter: InventionFilter.INVENTION_UTILITY
  };

  if (isCorrectDate(query)) {
    setFilters.date = query.date ? query.date : null;
  }
  setFilters.query = query.query || null;
  setFilters.mkpoClassId = query.mkpoClassId ? +(query.mkpoClassId) : null;
  setFilters.inventionFilter = query?.inventionFilter ? +(query?.inventionFilter) : InventionFilter.INVENTION_UTILITY;

  if (isPositiveIntNumber(query.size)) {
    setPaginator.size = query.size ? +query.size : defaultPaginatorSettings.size;
  }
  if (isPositiveIntNumber(query.page)) {
    setPaginator.page = query.page ? +query.page : defaultPaginatorSettings.first;
  }

  store.commit(`${MODULES_NAMES.MODELS}/${MODELS_MUTATIONS_TYPE.SET_INITIAL_FILTERS}`, {
    filters: setFilters
  });

  store.commit(`${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_INITIAL_PAGINATOR}`, {
    paginator: setPaginator
  });
};

export default setPaginatorAndFiltersFromQuery;
