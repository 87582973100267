import { computed, defineComponent, PropType } from 'vue';
import type { TModelData } from '@/types/modelType';
import { ModelFileFormats, ModelStatus } from '@/types/modelType';
import { getAuthImage } from '@/utils/getAuthImage';
import { getModelImageUrl, getModelPartImageUrl } from '@/components/Model/utils/getImageUrl';

export default defineComponent({
  name: 'BaseAuthCardImg',

  props: {
    data: {
      type: Object as PropType<TModelData>,
      required: true
    },
    detailUuid: {
      type: String
    },
    className: {
      type: String
    },
    alt: {
      type: String
    }
  },

  setup(props) {
    const getAuthImageForCard = computed(() => {
      if (props.data.status === ModelStatus.FAILED) {
        return require('@/assets/images/FAILED.svg');
      }
      if (props.data.status === ModelStatus.PROCESSING) {
        return require('@/assets/images/PROCESSING.svg');
      }
      if (props.data.modelFileFormat?.id === ModelFileFormats.PRC) {
        return require('@/assets/images/PRC.svg');
      }
      if (props.detailUuid) {
        return getAuthImage(getModelPartImageUrl(props.data.id!, props.detailUuid));
      }
      return getAuthImage(getModelImageUrl(props.data.id!));
    });
    return { getAuthImageForCard };
  }
});
