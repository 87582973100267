<template>
  <div class="p-field ui-input-field ui-calendar">
    <label
      :class="`${!!errorMessage && 'ui-error-label' }`"
      :for='`uid${name}`'
    >
      {{ label }}
    </label>
    <span :class="`p-input-icon-right ${hasRemoveIcon && 'p-input-remove-icon'}`">
      <i class="pi pi-calendar" />
       <span
         v-if="hasRemoveIcon && inputValue"
         class="reset-icon"
         @click="resetField()"
       >
         <img src="@/assets/icons/close.svg" />
       </span>
      <Calendar
        :id='`uid${name}`'
        v-model="inputValue"
        :name="name"
        selectionMode="range"
        :placeholder="placeholder"
        dateFormat="dd.mm.yy"
        @date-select="selectDate()"
        @update:modelValue="setInputDate($event)"
        yearNavigator
        :yearRange="YEAR_RANGE"
        monthNavigator
        :manualInput="false"
        appendTo="self"
      >
        <template v-slot:footer>
          <div class="ui-calendar-footer">
            <BaseButton
              v-for="item in chipsList"
              :class="`${isActiveButton(item.key) && 'active'}`"
              :key="item.key"
              :label="item.label"
              outlined
              @click="setDates(item.dates, item.key)"
            />
<!--            <BaseButton-->
<!--              :class="`${isActiveButton(CalendarRanges.YESTERDAY) && 'active'}`"-->
<!--              label="Вчера"-->
<!--              outlined-->
<!--              @click="setYesterday()"-->
<!--            />-->
<!--            <BaseButton-->
<!--              :class="`${isActiveButton(CalendarRanges.WEEK_AGO) && 'active'}`"-->
<!--              label="Последние 7 дней"-->
<!--              outlined-->
<!--              @click="setLastWeek()"-->
<!--            />-->
<!--            <BaseButton-->
<!--              :class="`${isActiveButton(CalendarRanges.MONTH_AGO) && 'active'}`"-->
<!--              label="Последние 30 дней"-->
<!--              outlined-->
<!--              @click="setLastMonth()"-->
<!--            />-->
          </div>
        </template>
      </Calendar>
    </span>
    <small
      v-if="!!errorMessage"
      class="p-error ui-error-label"
    >{{ errorMessage }}</small>
  </div>
</template>

<script src="./BaseInputCalendar.ts" lang="ts"/>
<style src="./BaseInputCalendar.scss" lang="scss" />
