export const setBeginOfDay = (date: Date) => {
  return date.setHours(0, 0, 0, 0);
};

export const setEndOfDay = (date: Date) => {
  return date.setHours(23, 59, 59, 0);
};

export const setMonthsAgo = (number = 1) => {
  const now = new Date();
  return new Date(now.setMonth(now.getMonth() - number));
};

export const getQuarterBounds = (date?: Date) => {
  date = date || new Date();

  return [
    new Date(new Date().setMonth(
      date.getMonth() - date.getMonth() % 3, 1)),
    new Date(new Date().setMonth(
      date.getMonth() + 3 - date.getMonth() % 3, 0))
  ];
};

export const setDaysAgo = (number = 1) => {
  const now = new Date();
  return new Date(now.setDate(now.getDate() - number));
};
